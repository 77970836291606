export {}
;(function (window) {
  if (typeof window.CustomEvent === 'function') {
    return
  }

  const CustomEvent = function (event, params) {
    params = params || {
      bubbles: false,
      cancelable: false,
      detail: undefined,
    }

    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype
  // @ts-expect-error
  window.CustomEvent = CustomEvent
})(window)
